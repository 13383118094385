<template>
    <div class="qr-code-page">

        <v-overlay :value="isLoading" color="white" opacity="1">
            <div class="spinner-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </v-overlay>

        <v-container fluid>
            <div class="main py-10">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="5">

                        <div class="text-center">
                            <div>
                                <img alt="" src="../../assets/logo_gifty.svg" width="150"/>
                            </div>

                            <h1 class="font-weight-medium my-5 grad-text">
                                Payé avec Gifty DZ
                            </h1>
                        </div>

                        <div class="text-center bg-primary-subtle rounded-lg pa-15">
                            <qrcode-vue :size="200"
                                        :value="code"
                                        background="transparent"
                                        foreground="#d60c56"/>


                            <a class="gifty--text font-weight-medium mt-5"
                               href="https://www.jumia.com.dz">
                                https://www.jumia.com.dz
                            </a>

                            <h3 class="mt-5 gifty--text font-weight-medium">
                                Scannez ce QR code par l'application Gifty DZ.
                            </h3>
                        </div>
                    </v-col>

                </v-row>
            </div>


            <v-btn color="primary" depressed @click="test">Payé avec gifty dz</v-btn>

        </v-container>

        <FooterP/>

    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import axios from "axios";
import FooterP from "@/views/qr_code/components/FooterP.vue";

export default {
    components: {
        FooterP,
        QrcodeVue,
    },
    data() {
        return {
            isLoading: false,
            code: "null"
        }
    },
    methods: {
        test() {
            axios.post('http://127.0.0.1:8001/api/external/users/test', {
                amount: 2500.00,
                order_id: 1780,
                marchant_id: "GIFTY-ASDVOPMF475228DUUDNHTSKAPOIDKLEKISYRHRPDN5584920DLH6D4DJS2ZZ45-DZ",
                return_url: "https://www.jumia.com.dz",
            }).then((res) => {
                 window.open(res.data.qrcode_url, "_blanc")
            }).catch(err => {
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>
.qr-code-page {
    background: white;
    min-height: 100vh;
}

.bg-primary-subtle {
    background: rgba(214, 12, 86, 0.15);
}

.grad-text {
    background: linear-gradient(to right, #d60c56, #d60c56, #FFAB02, #FFAB02);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
</style>