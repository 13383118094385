<template>
    <div>
        <v-footer class="footer" color="gifty" dark padless>
            <v-container>
                <v-card class="white--text"
                        color="gifty"
                        flat
                        tile
                        width="100%">
                    <v-card-text class="pa-0 ma-0">
                        <v-row align="center" class="py-3">
                            <v-col cols="12" lg="6">
                                <div>
                                    <img alt="" src="../../../assets/logo_gifty_white.svg" width="100"/>
                                </div>

                                <p class="white--text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br> Praesent efficitur mi
                                    nulla, at
                                    tempor felis dapibus quis.
                                </p>

                                <v-btn v-for="icon in icons"
                                       :key="icon"
                                       icon>
                                    <v-icon size="24px">
                                        {{ icon }}
                                    </v-icon>
                                </v-btn>

                            </v-col>

                            <v-col class="text-lg-end text-center white--text"
                                   cols="12"
                                   lg="6">
                                Tous droits réservés. <a class="font-weight-medium"
                                                         href="https://gifty.dz/"
                                                         target="_blank">Gifty DZ</a> © {{ new Date().getFullYear() }}

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-footer>
    </div>
</template>

<script>
export default {
    name: "FooterP",
    data() {
        return {
            icons: [
                'mdi-facebook',
                'mdi-linkedin',
                'mdi-instagram',
            ]
        }
    },
}
</script>

<style scoped>
.footer {
    bottom: -20px;
}
</style>